import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import ResponsiveSpacing from "../components/responsive-spacing"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import FormButton from "../components/form-button"
import Inner from "../components/inner"
import Status from "../components/status"
import SEO from "../components/seo"
import Shopify from "../utils/shopify"
import { Row, Col } from "../components/rows"
import Input from "../components/inputs"
import { useStaticQuery, navigate, graphql } from "gatsby"
import EMAIL_REGEXP from "../utils/email-regexp"

const errorMsgs = {
  minLength: "Must be 8+ characters",
  confirm_password: "Password must match confirmation",
  required: "Required",
  email: "E-Mail Address is invalid",
}

const LoginForms = styled.div`
  margin: 143px auto 150px auto;
  min-height: 270px;
  @media (max-width: 1168px) {
    margin: 143px auto 50px auto;
    padding: 0 10px;
  }

  .switchForm {
    text-align: center;
    display: block;
    font-size: 1.3em;
    font-weight: bold;
    padding: 10px 0;
    color: ${(props) => props.theme.DarkBlue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Heading = styled.h2`
  font-size: 2em;
  color: ${(p) => p.theme.Blue};
`

const FormInfo = styled.p`
  font-size: 1.4em;
  margin-bottom: 30px;
  color: ${(p) => p.theme.DarkBlue};
`

const RecoverInfoStyled = styled.form`
  padding: 40px 0;
  min-height: 290px;

  input {
    display: block;
  }
`
const RecoverInfo = ({ onClick, fields }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const { register, handleSubmit, errors } = useForm()

  const required = (name) => {
    if (!errors[name]) return
    if (errors[name].message) return errors[name].message
    const type = errors[name].type
    if (errorMsgs[type]) return errorMsgs[type]
  }

  const onSubmit = (data) => {
    setLoading(true)
    Shopify.post("/recover", data)
      .then((raw) => raw.json())
      .then((res) => {
        setLoading(false)
        if (!res.errors && res.data.customerRecover.userErrors.length === 0) {
          setStatus({
            code: 1,
            msg: "We have sent you a e-mail with a link to reset.",
          })
        } else {
          const errorMsg =
            res.data.customerRecover !== null
              ? res.data.customerRecover.userErrors[0]
              : res.errors[0]

          const errorOutput =
            errorMsg.message === "Could not find customer"
              ? errorMsg.message +
                ". Sign up to create an account with a password."
              : errorMsg.message

          setStatus({
            code: 0,
            msg: errorOutput,
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setStatus({ code: 0, msg: err })
      })
  }

  return (
    <RecoverInfoStyled onSubmit={handleSubmit(onSubmit)}>
      <Inner>
        <Heading>{fields.forgot_password_heading}</Heading>
        <FormInfo>{fields.forgot_password_sub_heading}</FormInfo>
        <Input
          placeholder="E-mail"
          name="email"
          error={required("email")}
          register={register}
          registerValue={{
            required: true,
            pattern: {
              value: EMAIL_REGEXP,
              message: errorMsgs.email,
            },
          }}
        />
        <Status status={status} setStatus={setStatus} />
        <Spacer height="20px" />
        <FormButton loading={loading}>
          {loading ? "PROCESSING" : fields.forgot_password_button_label}
        </FormButton>
      </Inner>
    </RecoverInfoStyled>
  )
}

const SignupInfoStyled = styled.form`
  padding: 40px 0;

  .agreeTOS {
    display: block;
    padding: 10px 0;
    font-size: 1.4em;
    color: ${(p) => p.theme.DarkBlue};
    a {
      color: ${(p) => p.theme.DarkBlue};
    }
  }
`

const SignupInfo = ({ dispatch, fields }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const { register, handleSubmit, errors, getValues } = useForm()

  const onSubmit = (formData) => {
    setLoading(true)
    Shopify.post("/signup", formData)
      .then((raw) => raw.json())
      .then((res) => {
        const errors = res.create.data.errors || false
        if (errors && errors.length > 0) {
          setLoading(false)
          setStatus({ code: 0, msg: errors[0].message })
          return
        }
        if (res.login) {
          setLoading(false)
          const user =
            res.login.data.customerAccessTokenCreate.customerAccessToken
          dispatch({ type: "LOGIN", user })
          setStatus({ code: 1, msg: "Success. Logging in now." })
        } else {
          setLoading(false)
          setStatus({
            code: 1,
            msg: "Customer created but not logged in.  Redirecting to login.",
          })
          window.location.href = "/login"
        }
      })
      .catch((err) => {
        setLoading(false)
        setStatus({ code: 0, msg: err })
        console.log(err)
      })
  }

  const required = (name) => {
    if (!errors[name]) return
    if (errors[name].message) return errors[name].message
    const type = errors[name].type
    if (errorMsgs[type]) return errorMsgs[type]
  }

  return (
    <SignupInfoStyled onSubmit={handleSubmit(onSubmit)}>
      <Inner>
        <Heading>{fields.register_heading}</Heading>
        <FormInfo>{fields.register_sub_heading}</FormInfo>
        <Input
          placeholder="First Name"
          name="firstName"
          register={register}
          registerValue={{
            required: true,
          }}
          error={required("firstName")}
        />
        <Input
          placeholder="Last Name"
          name="lastName"
          register={register}
          error={required("lastName")}
          registerValue={{
            required: true,
          }}
        />
        <Input
          placeholder="E-mail"
          name="email"
          error={required("email")}
          register={register}
          registerValue={{
            required: true,
            pattern: {
              value: EMAIL_REGEXP,
              message: errorMsgs.email,
            },
          }}
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          error={required("password")}
          register={register}
          registerValue={{
            required: true,
            minLength: 8,
            message: errorMsgs.password,
          }}
        />

        <Input
          type="password"
          name="confirm_password"
          placeholder="Confirm Password"
          error={required("confirm_password")}
          register={register}
          registerValue={{
            required: errorMsgs.confirm_password,
            validate: {
              confirm_password: (value) => {
                const { password } = getValues()
                return password === value || errorMsgs.confirm_password
              },
            },
          }}
        />
        <span
          className="agreeTOS"
          dangerouslySetInnerHTML={{
            __html: fields.register_tos_agree,
          }}
        />
        <Status status={status} setStatus={setStatus} />
        <Spacer height="20px" />
        <FormButton loading={loading}>
          {loading ? "PROCESSING" : fields.register_button_label}
        </FormButton>
      </Inner>
    </SignupInfoStyled>
  )
}

const ConnectedSignup = connect((state) => state)(SignupInfo)

const LoginInfoStyled = styled.form`
  padding: 40px 0 20px 0;
  min-height: 290px;
  width: 100%;
`

const LoginInfo = ({ dispatch, user, fields }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    if (user.accessToken) navigate("/my-account")
  }, [user])

  const onSubmit = (formData) => {
    setLoading(true)
    Shopify.post("/login", formData)
      .then((raw) => raw.json())
      .then((res) => {
        if (
          res.data.customerAccessTokenCreate.customerUserErrors.length === 0
        ) {
          const user = res.data.customerAccessTokenCreate.customerAccessToken
          const { accessToken, expiresAt } = user
          dispatch({ type: "LOGIN", user })
        } else {
          setStatus({
            code: res.data.customerAccessTokenCreate.customerUserErrors[0].code,
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setStatus({ code: 0, msg: err })
      })
  }

  const required = (name) => {
    if (!errors[name]) return
    const type = errors[name].type
    if (errorMsgs[type]) return errorMsgs[type]
    if (errors[name].message) return errors[name].message
  }

  return (
    <LoginInfoStyled onSubmit={handleSubmit(onSubmit)}>
      <Inner>
        <Heading>{fields.login_heading}</Heading>
        <FormInfo>{fields.login_sub_heading}</FormInfo>
        <Input
          placeholder="E-mail"
          name="email"
          error={required("email")}
          register={register}
          registerValue={{
            required: true,
            pattern: {
              value: EMAIL_REGEXP,
              message: errorMsgs.email,
            },
          }}
        />
        <Input
          type="password"
          name="password"
          placeholder="Password"
          error={required("password")}
          register={register}
          registerValue={{
            required: true,
            minLength: 8,
            message: errorMsgs.minLength,
          }}
        />
        <Status status={status} setStatus={setStatus} />
        <Spacer height="20px" />
        <FormButton loading={loading}>
          {loading ? "PROCESSING" : fields.login_button_label}
        </FormButton>
      </Inner>
    </LoginInfoStyled>
  )
}

const ConnectedLogin = connect((state) => state)(LoginInfo)

const LoginPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "login-page" } }) {
        edges {
          node {
            acf {
              login_heading
              login_link
              login_button_label
              login_sub_heading

              register_heading
              register_sub_heading
              register_button_label
              register_tos_agree

              forgot_password_heading
              forgot_password_sub_heading
              forgot_password_link
              forgot_password_button_label

              register_tos_agree

              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields

  const [loginForm, setLoginForm] = useState(true)
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <h1 style={{ display: "none" }}>{search_title}</h1>
      <LoginForms>
        <ResponsiveSpacing>
          <Row gap="60px" mobileMargin="0px">
            <Col>
              {loginForm ? (
                <ConnectedLogin fields={fields} />
              ) : (
                <RecoverInfo fields={fields} />
              )}
              <a
                className="switchForm"
                href="#"
                onClick={(e) => setLoginForm(!loginForm)}
              >
                {loginForm ? fields.forgot_password_link : fields.login_link}
              </a>
            </Col>
            <Col>
              <ConnectedSignup fields={fields} />
            </Col>
          </Row>
        </ResponsiveSpacing>
      </LoginForms>
    </Layout>
  )
}

export default LoginPage
